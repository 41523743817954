import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../../Components/ui/Text';
import { faBell, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useGetUpcomingRoundsQuery } from '../../../features/round/roundApiSlice';
import RoundSummary from '../../../features/round/types/RoundSummary';
import { format } from 'date-fns';

const MyRoundsPage = () => {
	const { data: rounds } = useGetUpcomingRoundsQuery();
	console.log(rounds);
	return (
		<div className='p-4'>
			<div className='flex justify-between mb-4'>
				<Text variant='h2'>My Rounds</Text>
				<span className='flex items-center justify-center w-10 h-10 rounded-full bg-muted-gray'>
					<FontAwesomeIcon
						icon={faBell}
						className='h-4 w-4 text-gray-700'
					/>
				</span>
			</div>
			<div className='mt-4'>
				<Text variant='body2' className='font-semibold'>
					Upcoming ({rounds?.length})
				</Text>

				<div className='space-y-2 mt-1'>
					{rounds?.map((round: RoundSummary, index: number) => (
						<div
							className='border rounded-md p-2 flex flex-col justify-between'
							//onClick={() => onClick(tournament.tournamentId)}
						>
							<div className='space-y-3'>
								<div className='space-y-2'>
									<div>
										<Text variant='h6' color='dark'>
											{round.courseName}
										</Text>
									</div>
									<div className='flex flex-col space-y-1'>
										<div className='flex flex-row space-x-2 items-baseline'>
											<FontAwesomeIcon
												icon={faCalendar}
												className='text-xs text-gray-500'
											/>
											<Text variant='fine1' color='light'>
												{format(
													round.roundDate,
													'EEE do MMMM yyyy'
												)}
											</Text>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default MyRoundsPage;
