import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import authReducer from './slices/authSlice';
import storage from 'redux-persist/lib/storage';
import { courseApiSlice } from '../features/course/courseApiSlice';
import roundReducer from '../features/round/roundSlice';
import { roundApiSlice } from '../features/round/roundApiSlice';
import { tournamentApiSlice } from '../features/tournament/tournamentApiSlice';
import { userApiSlice } from '../features/user/userApiSlice';
import { friendApiSlice } from '../features/friend/friendApiSlice';
import listenerMiddleware from './middleware/listenerMiddleware';

const persistConfig = {
	key: 'root',
	storage,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
	reducer: {
		auth: persistedReducer,
		round: roundReducer,
		[courseApiSlice.reducerPath]: courseApiSlice.reducer,
		[roundApiSlice.reducerPath]: roundApiSlice.reducer,
		[tournamentApiSlice.reducerPath]: tournamentApiSlice.reducer,
		[userApiSlice.reducerPath]: userApiSlice.reducer,
		[friendApiSlice.reducerPath]: friendApiSlice.reducer,
	},
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware()
			.concat(roundApiSlice.middleware)
			.concat(courseApiSlice.middleware)
			.concat(tournamentApiSlice.middleware)
			.concat(userApiSlice.middleware)
			.concat(friendApiSlice.middleware)
			.concat(listenerMiddleware.middleware);
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
export default store;
