import { layouts } from 'chart.js';
import axiosClient from '../Shared/ApiClient';

export const getCourse = async (courseId) => {
	try {
		const response = await axiosClient.get(`/Course?courseId=${courseId}`);
		return response.data;
	} catch (error) {
		console.error('Error fetching course:', error);
		throw error;
	}
};

export const getCourses = async () => {
	try {
		const response = await axiosClient.get('/Course/all');
		return response.data;
	} catch (error) {
		console.error('Error fetching courses:', error);
		throw error;
	}
};

export const createCourse = async (courseName, courseLayout) => {
	try {
		const newCourseRequest = {
			name: courseName,
			layouts: [courseLayout],
		};
		await axiosClient.post('Course', newCourseRequest);
	} catch (error) {
		console.error(error);
	}
};

export const addLayout = async (courseId, newLayout) => {
	try {
		await axiosClient.post(
			`Course/add-layout?courseId=${courseId}`,
			newLayout
		);
	} catch (error) {
		console.error(error);
	}
};

export const searchCoursesNearby = async (latitude, longitude, radius) => {
	try {
		const response = await axiosClient.get(
			`Course/SearchNearby?latitude=${latitude}&longitude=${longitude}&radius=${radius}`
		);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};
