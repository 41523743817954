import React, { useEffect, useState } from 'react';
import { fetchRound } from '../../Shared/roundService';
import { getRoundSummary } from '../../Services/roundService';
import { formatDate } from '../../utils/dateFormatter';
import { useParams, useNavigate } from 'react-router-dom';
import ScorecardTemplate from './ScorecardTemplate';
import { CgClose } from 'react-icons/cg';

const RoundSummary = () => {
	const { roundId } = useParams();
	const navigate = useNavigate();
	const [round, setRound] = useState({});
	useEffect(() => {
		getRoundSummary(roundId).then((response) => {
			console.log(response);
			setRound(response);
		});
	}, [roundId]);

	const selectHoles = (from, to) => {
		return round.scorecard.scores
			.filter((hole) => hole.holeNumber >= from && hole.holeNumber <= to)
			.sort((a, b) => a.holeNumber - b.holeNumber);
	};

	return (
		<div className='px-32 py-10 space-y-8'>
			{round.scorecard ? (
				<div className='space-y-8'>
					<div className='flex flex-row justify-between'>
						<div>
							<div>
								<h3 className='text-2xl text-gray-600 font-medium tracking-wider'>
									{round.courseName}
								</h3>
								<div className='space-x-4'>
									<span className='text-base text-gray-600 tracking-wider'>
										{round.scorecard.tee} Tees
									</span>
									<span className='text-base text-gray-600 tracking-wider'>
										{`Par ${round.scorecard.par}`}
									</span>
									<span className='text-base text-gray-600 tracking-wider'>
										{round.scorecard.length}m
									</span>
								</div>
							</div>
							<div>
								<span className='text-base font-semibold text-gray-600 tracking-wider'>
									{`Played ${formatDate(
										round.scorecard.datePlayed
									)}`}
								</span>
							</div>
						</div>
						<span
							className='cursor-pointer'
							onClick={() => navigate('/my-rounds')}
						>
							<CgClose
								style={{ color: 'rgb(75 85 99)' }}
								size={35}
							/>
						</span>
					</div>
					<div className='border rounded-md border-gray-400'>
						<div className='flex justify-between'>
							<div className='flex flex-col justify-center items-center p-8 space-y-3 w-1/3'>
								<span className='text-6xl text-gray-600 tracking-wider'>
									{round.stats.fairwayPercentage}%
								</span>
								<span className='text-sm tracking-widest text-gray-500 font-medium'>
									Fairways Hit
								</span>
							</div>
							<div className='flex flex-col justify-center items-center p-8 space-y-2 w-1/3'>
								<span className='text-6xl text-gray-600 tracking-wider'>
									{round.stats.greenInRegulationPercentage}%
								</span>
								<span className='text-sm tracking-widest text-gray-500 font-medium'>
									Green In Regulation
								</span>
							</div>
							<div className='flex flex-col justify-center items-center p-8 space-y-2 w-1/3'>
								<span className='text-6xl text-gray-600 tracking-wider'>
									{round.stats.totalPutts}
								</span>
								<span className='text-sm tracking-widest text-gray-500 font-medium'>
									Putts
								</span>
							</div>
						</div>
						<div className='flex justify-between'>
							<div className='flex flex-col justify-center items-center p-8 space-y-3 w-1/3'>
								<span className='text-6xl text-gray-600 tracking-wider'>
									{round.stats.scramblingPercentage}%
								</span>
								<span className='text-sm tracking-widest text-gray-500 font-medium'>
									Scrambling
								</span>
							</div>
							<div className='flex flex-col justify-center items-center p-8 space-y-2 w-1/3'>
								<span className='text-6xl text-gray-600 tracking-wider'>
									{round.stats.upAndDownPercentage !== null
										? round.stats.upAndDownPercentage + '%'
										: '-'}
								</span>
								<span className='text-sm tracking-widest text-gray-500 font-medium'>
									Up & Down
								</span>
							</div>
							<div className='flex flex-col justify-center items-center p-8 space-y-2 w-1/3'>
								<span className='text-6xl text-gray-600 tracking-wider'>
									{round.stats.sandSavePercentage !== null
										? round.stats.sandSavePercentage + '%'
										: '-'}
								</span>
								<span className='text-sm tracking-widest text-gray-500 font-medium'>
									Sand Saves
								</span>
							</div>
						</div>
					</div>
					<ScorecardTemplate
						title='Front Nine'
						subTitle='Out'
						holes={selectHoles(1, 9)}
					/>
					<ScorecardTemplate
						title='Back Nine'
						subTitle='In'
						holes={selectHoles(10, 18)}
					/>
				</div>
			) : (
				<p>Loading course data...</p>
			)}
		</div>
	);
};

export default RoundSummary;
