import React, { useState, useEffect } from 'react';
import InputField from './InputField';
import { addLayout, createCourse } from '../Services/courseService';

const AddCourseModal = ({ isOpen, closeModal, courseId, courseName }) => {
	const [name, setName] = useState(courseName);
	const [courseLayout, setCourseLayout] = useState({
		tee: '',
		holes: Array.from({ length: 18 }, (_, index) => ({
			holeNumber: index + 1,
			par: null,
			length: null,
		})),
	});

	if (!isOpen) return null;

	const handleHoleChange = (holeIndex, field, value) => {
		const updatedTees = {
			...courseLayout.tee,
			holes: courseLayout.holes.map((hole, hIndex) => {
				if (hIndex === holeIndex) {
					return { ...hole, [field]: parseInt(value, 10) };
				}
				return hole;
			}),
		};
		console.log(updatedTees);
		setCourseLayout(updatedTees);
	};

	const sumTotal = (from, to, field) => {
		//console.log(courseLayout);
		return courseLayout.holes
			.filter((hole) => hole.holeNumber >= from && hole.holeNumber <= to)
			.sort((a, b) => a.holeNumber - b.holeNumber)
			.reduce((accumulator, currentHole) => {
				if (currentHole[field] !== null) {
					return accumulator + currentHole[field];
				}
				return accumulator;
			}, 0);
	};

	const handleTeeNameChange = (e) => {
		setCourseLayout({ ...courseLayout, tee: e.target.value });
	};

	const saveChanges = async () => {
		try {
			if (courseId == null) {
				await createCourse(name, courseLayout);
			} else {
				console.log(courseLayout);
				addLayout(courseId, courseLayout);
			}

			closeModal();
		} catch (error) {
			console.error('Error adding documents: ', error);
		}
	};

	return (
		<div className='w-full fixed inset-0 bg-white z-50 flex justify-center items-center'>
			<div className='bg-white space-y-6'>
				<div className='w-64'>
					<label
						htmlFor='courseName'
						className='block text-sm font-normal tracking-wider leading-6 text-gray-900'
					>
						Course Name
					</label>
					<div>
						<input
							type='text'
							name='courseName'
							id='courseName'
							value={name}
							onChange={(e) => setName(e.target.value)}
							className='block w-full border-0 rounded px-3.5 py-2 text-gray-900 text-base tracking-wider font-light shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
						/>
					</div>
				</div>
				<div className='w-64'>
					<label
						htmlFor='teeName'
						className='block text-sm font-normal tracking-wider leading-6 text-gray-900'
					>
						Tee Name
					</label>
					<div>
						<input
							type='text'
							name='tee'
							id='teeName'
							value={courseLayout.tee}
							onChange={handleTeeNameChange}
							className='block w-full border-0 rounded px-3.5 py-2 text-gray-900 text-base tracking-wider font-light shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
						/>
					</div>
				</div>
				<div className='space-y-12'>
					<div>
						<div className='bg-gray-200 flex flex-row justify-between p-4 border-b border-gray-400'>
							<div className='flex flex-row'>
								<span className='text-lg text-gray-700 font-medium tracking-wider min-w-[100px] mr-32'>
									Front Nine
								</span>
								<div className='flex flex-row min-w-[900px] basis-[54%]'>
									{courseLayout.holes
										.filter(
											(hole) =>
												hole.holeNumber >= 1 &&
												hole.holeNumber <= 9
										)
										.map((hole, holeIndex) => (
											<div
												key={holeIndex}
												className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
											>
												<span
													key={holeIndex}
													className='text-lg text-gray-500'
												>
													{hole.holeNumber}
												</span>
											</div>
										))}
								</div>
							</div>
							<span className='text-lg text-gray-700 font-medium tracking-wider'>
								Out
							</span>
						</div>
						<div className='flex flex-row justify-between p-4 border-b border-gray-400'>
							<div className='flex flex-row '>
								<span className='text-lg text-gray-700 font-medium tracking-wider min-w-[100px] mr-32'>
									Par
								</span>
								<div className='flex flex-row min-w-[900px] basis-[54%]'>
									{courseLayout.holes
										.filter(
											(hole) =>
												hole.holeNumber >= 1 &&
												hole.holeNumber <= 9
										)
										.map((hole, holeIndex) => (
											<div
												key={holeIndex}
												className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
											>
												<span className='text-lg text-gray-500'>
													<InputField
														holeIndex={holeIndex}
														value={hole.par}
														type='par'
														handleHoleChange={
															handleHoleChange
														}
													/>
												</span>
											</div>
										))}
								</div>
							</div>
							<span className='text-lg text-gray-500'>
								{sumTotal(1, 9, 'par')}
							</span>
						</div>
						<div className='flex flex-row justify-between p-4 border-b border-gray-400'>
							<div className='flex flex-row '>
								<span className='text-lg text-gray-700 font-medium tracking-wider min-w-[100px] mr-32'>
									Length
								</span>
								<div className='flex flex-row min-w-[900px] basis-[54%]'>
									{courseLayout.holes
										.filter(
											(hole) =>
												hole.holeNumber >= 1 &&
												hole.holeNumber <= 9
										)
										.map((hole, holeIndex) => (
											<div
												key={holeIndex}
												className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
											>
												<span className='text-lg text-gray-500'>
													<InputField
														holeIndex={holeIndex}
														value={hole.length}
														type='length'
														handleHoleChange={
															handleHoleChange
														}
													/>
												</span>
											</div>
										))}
								</div>
							</div>
							<span className='text-lg text-gray-500'>
								{sumTotal(1, 9, 'length')}
							</span>
						</div>
					</div>
					<div>
						<div className='bg-gray-200 flex flex-row justify-between p-4 border-b border-gray-400'>
							<div className='flex flex-row'>
								<span className='text-lg text-gray-700 font-medium tracking-wider min-w-[100px] mr-32'>
									Back Nine
								</span>
								<div className='flex flex-row min-w-[900px] basis-[54%]'>
									{courseLayout.holes
										.filter(
											(hole) =>
												hole.holeNumber >= 10 &&
												hole.holeNumber <= 18
										)
										.map((hole, holeIndex) => (
											<div
												key={holeIndex}
												className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
											>
												<span
													key={holeIndex}
													className='text-lg text-gray-500'
												>
													{hole.holeNumber}
												</span>
											</div>
										))}
								</div>
							</div>
							<span className='text-lg text-gray-700 font-medium tracking-wider'>
								In
							</span>
						</div>
						<div className='flex flex-row justify-between p-4 border-b border-gray-400'>
							<div className='flex flex-row '>
								<span className='text-lg text-gray-700 font-medium tracking-wider min-w-[100px] mr-32'>
									Par
								</span>
								<div className='flex flex-row min-w-[900px] basis-[54%]'>
									{courseLayout.holes
										.filter(
											(hole) =>
												hole.holeNumber >= 10 &&
												hole.holeNumber <= 18
										)
										.map((hole, holeIndex) => (
											<div
												key={holeIndex}
												className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
											>
												<span className='text-lg text-gray-500'>
													<InputField
														holeIndex={
															holeIndex + 9
														}
														value={hole.par}
														type='par'
														handleHoleChange={
															handleHoleChange
														}
													/>
												</span>
											</div>
										))}
								</div>
							</div>
							<span className='text-lg text-gray-500'>
								{sumTotal(10, 18, 'par')}
							</span>
						</div>
						<div className='flex flex-row justify-between p-4 border-b border-gray-400'>
							<div className='flex flex-row '>
								<span className='text-lg text-gray-700 font-medium tracking-wider min-w-[100px] mr-32'>
									Length
								</span>
								<div className='flex flex-row min-w-[900px] basis-[54%]'>
									{courseLayout.holes
										.filter(
											(hole) =>
												hole.holeNumber >= 10 &&
												hole.holeNumber <= 18
										)
										.map((hole, holeIndex) => (
											<div
												key={holeIndex}
												className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
											>
												<span className='text-lg text-gray-500'>
													<InputField
														holeIndex={
															holeIndex + 9
														}
														value={hole.length}
														type='length'
														handleHoleChange={
															handleHoleChange
														}
													/>
												</span>
											</div>
										))}
								</div>
							</div>
							<span className='text-lg text-gray-500'>
								{sumTotal(10, 18, 'length')}
							</span>
						</div>
					</div>
				</div>
				<div className='flex items-center justify-end space-x-4'>
					<button
						onClick={closeModal}
						className='
						w-fit
						rounded
						tracking-wider
						bg-white
						px-3.5 
						py-2.5 border border-[#d32f2f]
						ease-in
						duration-100 
						text-center 
						text-base 
						font-normal 
						text-[#d32f2f] 
						shadow-sm 
						focus-visible:outline 
						focus-visible:outline-2 
						focus-visible:outline-offset-2 
						focus-visible:outline-blue-700'
					>
						Cancel
					</button>
					<button
						onClick={saveChanges}
						className='
						w-fit
						rounded
						tracking-wider
						bg-[#00b7b1] 
            hover:bg-[#008793] 
						px-3.5 
						py-2.5 
						ease-in
						duration-100 
						text-center 
						text-base 
						font-normal 
						text-white 
						shadow-sm 
						focus-visible:outline 
						focus-visible:outline-2 
						focus-visible:outline-offset-2 
						focus-visible:outline-blue-700'
					>
						Save Changes
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddCourseModal;
