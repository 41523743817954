import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../Shared/axiosBaseQuery';
import { NewRoundRequest } from '../../models/Round/newRoundRequest';
import { roundApi } from '../../api/round.api';
import RoundDetailsDto from '../../models/Round/RoundDetailsDto';
import { GET } from '../shared/httpMethods';
import RoundSummary from './types/RoundSummary';

export const roundApiSlice = createApi({
	reducerPath: 'roundApi',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		fetchRounds: builder.query<RoundDetailsDto[], void>({
			queryFn: () => roundApi.fetchAll()
		}),
		createRound: builder.mutation<string, NewRoundRequest>({
			queryFn: (data) => roundApi.create(data)
			// providesTags(result, error, courseId) {
			// 	return [{ type: 'Course', courseId }];
			// },
		}),
		getUpcomingRounds: builder.query<RoundSummary[], void>({
			query: () => ({
				url: 'rounds/upcomingrounds',
				method: GET
			})
		})
	}),
});

export const { useCreateRoundMutation, useFetchRoundsQuery, useGetUpcomingRoundsQuery } = roundApiSlice;
