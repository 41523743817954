import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: 'AIzaSyAe1RV6ZNB5R9UduUGNP6NDX4O3nGKYwSg',
	authDomain: 'twotwenty-b7029.firebaseapp.com',
	projectId: 'twotwenty-b7029',
	storageBucket: 'twotwenty-b7029.appspot.com',
	messagingSenderId: '150699014481',
	appId: '1:150699014481:web:2e49e91b050c7972a3fb70',
	measurementId: 'G-84QQ3ZJ96R',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
