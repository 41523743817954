import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../Shared/axiosBaseQuery';
import GetTournamentByIdResponse from './models/GetTournamentByIdResponse';
import SearchTournamentsResponse from './models/SearchTournamentsResponse';
import TournamentRegistrationRequest from './models/TournamentRegistrationRequest';
import { DELETE, GET, POST } from '../shared/httpMethods';
import TournamentSummary from './models/TournamentSummary';

export const tournamentApiSlice = createApi({
	reducerPath: 'tournamentApi',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		getTournamentsForUser: builder.query<TournamentSummary[], void>({
			query: () => ({
				url: 'tournaments',
				method: GET,
			}),
		}),
		getTournamentById: builder.query<GetTournamentByIdResponse, string>({
			query: (id: string) => ({
				url: `tournaments/${id}`,
				method: GET,
			}),
		}),
		searchTournaments: builder.query<SearchTournamentsResponse[], void>({
			query: () => ({
				url: 'tournaments/explore',
				method: GET,
			}),
		}),
		createTournament: builder.mutation<string, FormData>({
			query: (formData) => ({
				url: 'tournaments',
				method: POST,
				data: formData,
			}),
		}),
		registerForTournament: builder.mutation<
			void,
			TournamentRegistrationRequest
		>({
			query: (request) => ({
				url: 'tournaments/register',
				method: POST,
				data: request,
			}),
		}),
		deleteTournament: builder.mutation<void, string>({
			query: (tournamentId: string) => ({
				url: `tournaments/${tournamentId}`,
				method: DELETE,
			}),
		}),
	}),
});

export const {
	useGetTournamentsForUserQuery,
	useCreateTournamentMutation,
	useGetTournamentByIdQuery,
	useSearchTournamentsQuery,
	useLazySearchTournamentsQuery,
	useRegisterForTournamentMutation,
	useDeleteTournamentMutation,
} = tournamentApiSlice;
