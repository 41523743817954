import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc, query } from 'firebase/firestore';
import { db } from '../config/firebase';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrash, FaInfo, FaPlus } from 'react-icons/fa';
import AddCourseModal from './AddCourseModal';
import ConfirmationModal from './ConfirmationModal';
import { deleteCourseAndSubcollections } from '../Shared/firestoreService';
import { getCourses } from '../Services/courseService';
import { formatDate } from '../utils/dateFormatter';
import SearchBar from './ui/SearchBar';

const Courses = () => {
	const navigate = useNavigate();
	const [courses, setCourses] = useState([]);
	const [filteredCourses, setFilteredCourses] = useState([]);
	const [isModalOpen, setModalOpen] = useState(false);
	const [isConfirmationOpen, setConfirmationOpen] = useState(false);
	const [courseToDelete, setCourseToDelete] = useState(null);
	const [filterText, setFilterText] = useState('');

	const openModal = () => {
		setModalOpen(true);
		console.log('opening');
	};
	const closeModal = () => setModalOpen(false);

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
			width: '30%',
		},
		{
			name: 'Added',
			selector: (row) => formatDate(row.added),
			width: '30%',
		},
		{
			name: 'Last Updated',
			selector: (row) => formatDate(row.lastUpdated),
			width: '30%',
		},
		{
			name: 'Manage',
			width: '10%',
			button: true,
			cell: (row) => (
				<div className='flex justify-between w-full items-center px-5'>
					<FaInfo
						style={{ cursor: 'pointer' }}
						onClick={() => handleView(row)}
					/>
					<FaEdit
						style={{
							cursor: 'pointer',
						}}
						className=''
						onClick={() => handleEdit(row)}
					/>
					<FaTrash
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setCourseToDelete(row);
							setConfirmationOpen(true);
						}}
					/>
				</div>
			),
		},
	];

	const customStyles = {
		headCells: {
			style: {
				fontSize: '1rem', // Set font size for header cells
				color: 'rgb(55 65 81);', // Set text color for header cells
				backgroundColor: '#F9F9F9', // Set background color for header cells
			},
		},
		cells: {
			style: {
				fontSize: '16px', // Set font size for body cells
				color: '#333333', // Set text color for body cells
			},
		},
	};

	useEffect(() => {
		getCourses().then((res) => {
			console.log(res);
			setCourses(res);
			setFilteredCourses(res)
		});
	}, [isModalOpen]);

	useEffect(() => {
		if (isModalOpen || isConfirmationOpen) {
			// Disable scrolling on body
			document.body.style.overflow = 'hidden';
		} else {
			// Enable scrolling
			document.body.style.overflow = 'visible';
		}

		// Cleanup function to reset overflow when component unmounts
		return () => {
			document.body.style.overflow = 'visible';
		};
	}, [isModalOpen, isConfirmationOpen]);

	const handleEdit = (course) => {
		navigate(`/courses/edit/${course.courseId}`);
	};

	const handleDelete = () => {
		deleteCourseAndSubcollections(courseToDelete.id)
			.then(() => {
				console.log('Deletion successful');
				const updatedCourses = courses.filter(
					(c) => c.id !== courseToDelete.id
				);
				console.log(updatedCourses);
				setCourses(updatedCourses);
				setFilteredCourses(updatedCourses);
				setCourseToDelete(null);
				setConfirmationOpen(false);
			})
			.catch((error) => {
				console.error('Failed to delete course', error);
			});
	};

	const handleFilterCourses = () => {
		console.log(filterText.trim().length);

		if (filterText.trim().length === 0) {
			setFilteredCourses(courses);
		} else {
			const filteredCourses = Array.isArray(courses)
				? courses
						.filter((course) =>
							course.name
								.toLowerCase()
								.includes(filterText.toLowerCase().trim())
						)
						.slice(0, 7)
				: [];

			setFilteredCourses(filteredCourses);
		}
	};

	return (
		<div className='p-default-container'>
			<AddCourseModal isOpen={isModalOpen} closeModal={closeModal} />
			<ConfirmationModal
				isOpen={isConfirmationOpen}
				message='Are you sure you want to delete this course?'
				onClose={() => {
					setConfirmationOpen(false);
				}}
				onConfirm={handleDelete}
			/>
			<div className='flex flex-row items-center justify-between'>
				<h1 className='text-2xl tracking-wider font-medium text-gray-800'>
					Courses
				</h1>
			</div>
			<div className='mt-10 space-y-4'>
				<div className='flex flex-row justify-between h-12'>
					<div className='flex flex-row'>
						<input
							type='text'
							placeholder='Course Name'
							className='border border-gray-300 bg-white rounded h-full px-4 focus:outline-none placeholder:tracking-wider tracking-wider text-gray-700'
							onChange={(e) => {
								setFilterText(e.target.value);
							}}
						/>
						<button
							onClick={handleFilterCourses}
							className='
					ml-2
					border 
					h-full w-28
					tracking-wider
					border-primary-blue 
					text-primary-blue 
					py-2 px-5 
					rounded 
					bg-white 
					hover:bg-primary-blue 
					hover:text-primary-blue-darker 
					font-medium 
					hover:border-primary-blue-darker 
					hover:bg-opacity-15'
						>
							Filter
						</button>
					</div>
					<button
						onClick={openModal}
						className='
            flex items-center justify-center
						rounded
						tracking-wider
						w-38 h-full
						px-3.5 
						py-2.5
						ease-in
						duration-100 
						text-center 
						text-base 
            font-medium
						text-white
						shadow-sm 
						bg-primary-blue
            hover:bg-primary-blue-darker
						focus-visible:outline 
						focus-visible:outline-2 
						focus-visible:outline-offset-2 
						focus-visible:outline-blue-700'
					>
						Create Course
					</button>
				</div>
				<DataTable
					columns={columns}
					data={filteredCourses}
					customStyles={customStyles}
					pagination={true}
				/>
			</div>
		</div>
	);
};

export default Courses;
