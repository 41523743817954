import { db } from '../config/firebase';
import {
	deleteDoc,
	doc,
	addDoc,
	collection,
	getDocs,
	query,
	updateDoc,
} from 'firebase/firestore';

export const fetchCourses = async () => {
	const coursesCollection = collection(db, 'courses');
	const courseSnapshot = await getDocs(coursesCollection);
	const coursesList = await Promise.all(
		courseSnapshot.docs.map(async (doc) => {
			const courseData = doc.data();
			const layoutsCollection = collection(doc.ref, 'layouts');
			const layoutSnapshot = await getDocs(layoutsCollection);
			const layouts = layoutSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			return { ...courseData, layouts, id: doc.id };
		})
	);

	return coursesList;
};

export const fetchHolesForCourse = async (courseId, layoutId) => {
	const holesCollection = collection(
		db,
		`courses/${courseId}/layouts/${layoutId}/holes`
	);
	const holesSnapshot = await getDocs(holesCollection);
	const holes = holesSnapshot.docs.map((doc) => ({
		id: doc.id,
		...doc.data(),
	}));

	return holes;
};

export const deleteCourseAndSubcollections = async (courseId) => {
	try {
		const courseRef = doc(db, 'courses', courseId);

		const layoutsQuery = query(
			collection(db, `courses/${courseId}/layouts`)
		);
		const layoutsSnapshot = await getDocs(layoutsQuery);
		const layoutDeletions = layoutsSnapshot.docs.map(async (layoutDoc) => {
			const layoutRef = doc(
				db,
				`courses/${courseId}/layouts`,
				layoutDoc.id
			);
			const holesQuery = query(
				collection(
					db,
					`courses/${courseId}/layouts/${layoutDoc.id}/holes`
				)
			);
			const holesSnapshot = await getDocs(holesQuery);

			const holeDeletions = holesSnapshot.docs.map((holeDoc) =>
				deleteDoc(
					doc(
						db,
						`courses/${courseId}/layouts/${layoutDoc.id}/holes`,
						holeDoc.id
					)
				)
			);

			await Promise.all(holeDeletions);
			return deleteDoc(layoutRef);
		});

		await Promise.all(layoutDeletions);
		await deleteDoc(courseRef);

		console.log('Course and all related data deleted successfully!');
	} catch (error) {
		console.error('Error deleting course and subcollections: ', error);
	}
};

export const addCourseAndLayout = async (courseName, teeName, courseLayout) => {
	try {
		const courseRef = await addDoc(collection(db, 'courses'), {
			name: courseName,
		});

		const layoutRef = await addDoc(
			collection(db, `courses/${courseRef.id}/layouts`),
			{
				tee: teeName,
			}
		);

		const promise = courseLayout.holes.map((hole) => {
			return addDoc(
				collection(
					db,
					`courses/${courseRef.id}/layouts/${layoutRef.id}/holes`
				),
				{
					holeNumber: hole.holeNumber,
					length: hole.length,
					par: hole.par,
				}
			);
		});

		await Promise.all(promise);
		//closeModal();
	} catch (error) {
		console.error('Error adding documents: ', error);
	}
};

export const addLayoutToCourse = async (courseId, teeName, courseLayout) => {
	try {
		const courseRef = doc(db, 'courses', courseId);

		const layoutRef = await addDoc(collection(courseRef, 'layouts'), {
			tee: teeName,
		});

		const promises = courseLayout.holes.map((hole) => {
			return addDoc(collection(layoutRef, 'holes'), {
				holeNumber: hole.holeNumber,
				length: hole.length,
				par: hole.par,
			});
		});

		await Promise.all(promises);
	} catch (error) {
		console.error(error);
	}
};

export const updateCourse = async (courseId) => {
	try {
		const courseDocRef = doc(db, `courses/${courseId}`);

		await updateDoc(courseDocRef, {
			name: 'Not Mornington',
		});
	} catch (error) {
		console.error('Error updating course name: ', error);
	}
};

export const updateHole = async (courseId, layoutId, hole) => {
	const holeRef = doc(db, `courses/${courseId}/layouts/${layoutId}/holes/${hole.id}`);
	const { updated, ...holeData } = hole;

	try {
    await updateDoc(holeRef, holeData);
    console.log(`Hole ${hole.id} updated successfully`);
  } catch (error) {
    console.error(`Error updating hole ${hole.id}: `, error);
    throw error; // Re-throw the error to handle it in the calling function
  }
}

export const createNewRound = async (courseId, layoutId) => {
	try {
		const roundsRef = collection(db, 'rounds');

		const newRound = {
			courseId: courseId,
			layoutId: layoutId,
			date: new Date(),
		};

		const ref = await addDoc(roundsRef, newRound);

		return {
			roundId: ref.id,
			courseId: courseId,
			layoutId: layoutId,
		};
	} catch (error) {
		console.error('Error creating new round:', error);
	}
};
