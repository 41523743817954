import { VariantProps, cva } from 'class-variance-authority';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { cn } from '../../utils/utils';
import { CircularProgress } from '@mui/material';

const buttonVariants = cva(
	'w-36 bg-primary-blue lg:hover:bg-primary-blue-darker active:bg-primary-blue-darker text-white tracking-wider',
	{
		variants: {
			variant: {
				default: 'border-gray-300',
				outline:
					'border border-primary-blue text-primary-blue bg-white hover:bg-primary-blue hover:text-primary-blue-darker hover:border-primary-blue-darker hover:bg-opacity-15',
				error: 'border-red-400',
				disabled:
					'bg-gray-300 hover:bg-gray-300 border-none active:bg-gray-300 cursor-not-allowed',
			},
			size: {
				default: 'h-10 rounded',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

interface ButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	className?: string;
	size?: 'default';
	variant?: 'default' | 'outline' | 'error' | 'disabled';
	children: ReactNode;
	disabled?: boolean;
	loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
	className,
	size,
	variant,
	type = 'button',
	children,
	disabled,
	loading,
	...props
}) => {
	return (
		<button
			type={type}
			className={cn(buttonVariants({ variant, size, className }))}
			disabled={disabled || loading}
			{...props}
		>
			{loading ? (
				<span className='space-x-2'>
					<CircularProgress size={15} color='inherit' />
					<span>Please wait</span>
				</span>
			) : (
				children
			)}
		</button>
	);
};

export { Button, buttonVariants };
