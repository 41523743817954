import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import axiosClient from './ApiClient';
import { RootState } from '../redux/store';

export const axiosBaseQuery =
	(): BaseQueryFn<
		{
			url: string;
			method?: AxiosRequestConfig['method'];
			data?: AxiosRequestConfig['data'];
			params?: AxiosRequestConfig['params'];
			headers?: AxiosRequestConfig['headers'];
		},
		unknown,
		unknown
	> =>
	async (args, api) => {
    const { url, method = 'GET', data, params } = args;
    const { getState } = api;
		try {
			const state = getState() as RootState;
      const token = state.auth.token;

      console.log('Token in axiosBaseQuery:', token);
			
			const headers: AxiosRequestConfig['headers'] = {
        Accept: 'application/json',
        'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
      };

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
			
			const result = await axiosClient({
				url,
				method,
				data,
				params,
				headers,
			});
			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError as AxiosError;
			console.log(err);
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};
