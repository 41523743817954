import React, { useState } from 'react';

const InputField = ({ holeIndex, value, type, handleHoleChange }) => {
	const [placeholder, setPlaceholder] = useState('-');

	const handleFocus = () => {
		setPlaceholder('');
	};

	const handleBlur = () => {
		if (value === null) {
			setPlaceholder('-');
		}
	};

	return (
		<input
			type='text'
			className='w-full text-center text-base text-gray-500 focus:outline-none'
			value={value}
			onChange={(e) => handleHoleChange(holeIndex, type, e.target.value)}
			placeholder={placeholder}
			onFocus={handleFocus}
			onBlur={handleBlur}
		/>
	);
};

export default InputField;
