import React, { useState } from 'react';
import { LayoutDetails } from '../../../models/courseLayout.type';
import Modal from '../../../Components/ui/Modal';
import { useModal } from '../../../Hooks/useModal';
import { Text } from '../../../Components/ui/Text';

interface TeeSelectProps {
	layouts: LayoutDetails[];
	onSelect: (layout: LayoutDetails) => void;
}

const TeeSelectModal: React.FC<TeeSelectProps> = ({ layouts, onSelect }) => {
	const [selectedLayout, setSelectedLayout] = useState<LayoutDetails | null>(
		null
	);
	const {
		isOpen: isModalOpen,
		openModal: openModal,
		closeModal: closeModal,
	} = useModal();

	const handleChange = (layout: LayoutDetails) => {
		setSelectedLayout(layout);
		onSelect(layout);
		closeModal();
	};

	return (
		<div>
			<div>
				<Text variant='h6' color='default' className='pl-2'>
					Tee
				</Text>
				<div
					className='bg-app-background px-4 py-2 mt-2 rounded-md cursor-pointer'
					onClick={openModal}
				>
					<Text variant='h6'>
						{selectedLayout !== null
							? `${selectedLayout.tee} (${selectedLayout.length})m`
							: 'Select Tee'}
					</Text>
				</div>
			</div>
			<Modal
				open={isModalOpen}
				onClose={closeModal}
				className='flex items-center justify-center bg-white'
			>
				<div className='w-full p-4 space-y-8 flex flex-col justify-start'>
					{layouts.map((layout) => (
						<span
							key={layout.layoutId}
							className='flex space-x-4'
							onClick={() => {
								handleChange(layout);
							}}
						>
							<Text variant='h6'>
								{layout.tee} ({layout.length}m)
							</Text>
						</span>
					))}
				</div>
			</Modal>
		</div>
	);
};

export default TeeSelectModal;
