import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Components/navigation/Navbar';
import { Outlet } from 'react-router-dom';
import TopNavbar from '../Components/navigation/TopNavbar';

const RootLayout = () => {
	const [vh, setVh] = useState(window.innerHeight * 0.01);
	const location = useLocation();
	const noNavbarRoutes = [
		//'/new-round',
		'/round-summary/:roundId',
		'/login',
		'/sign-up',
		'/new-round',
		'/new-tournament',
		'/tournament/:tournamentId',
		'/friends/search'
	];

	const showNavbar = !noNavbarRoutes.some((route) =>
		location.pathname.startsWith(route.split(':')[0])
	);

	useEffect(() => {
		const handleResize = () => {
			setVh(window.innerHeight * 0.01);
		};

		window.addEventListener('resize', handleResize);
		// Set the initial value on load
		setVh(window.innerHeight * 0.01);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		// Set the custom --vh variable for the viewport height
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}, [vh]);

	return (
		<div className='flex w-full min-h-[calc(var(--vh,1vh)*100)]'>
			{showNavbar && <Navbar />}
			{showNavbar && <TopNavbar />}
			<main
				className={`flex flex-col flex-grow bg-muted-60 ${
					showNavbar ? 'lg:pl-52 relative lg:pt-14' : 'pl-0 pr-0'
				}`}
			>
				{/* <StatsNavbar show={false} /> */}
				<Outlet />
			</main>
		</div>
	);
};

export default RootLayout;
