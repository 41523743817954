import React from 'react';

const ScorecardTemplate = ({ title, subTitle, holes }) => {
	const calculatePar = () => {
		return holes.reduce((acc, hole) => acc + hole.par, 0);
	};

	const calculateLength = () => {
		return holes.reduce((acc, hole) => acc + hole.length, 0);
	};

	const calculateScore = () => {
		return holes.reduce((acc, hole) => acc + hole.gross, 0);
	};

	const getScoreStyles = (hole) => {
		const scoreDifference = hole.gross - hole.par;

		if (scoreDifference === 0) {
			return 'bg-transparent text-gray-500';
		} else if (scoreDifference === 1) {
			return 'bg-blue-500 text-white';
		} else if (scoreDifference > 1) {
			return 'bg-black outline outline-black outline-offset-1 text-white';
		} else if (scoreDifference === -1) {
			return 'rounded-full bg-red-500 text-white';
		}
		return '';
	};

	return (
		<div>
			<div className='flex flex-row justify-between border-b border-gray-400 bg-gray-200'>
				<div className='flex flex-row '>
					<span className='flex justify-start items-center text-lg text-gray-700 font-medium tracking-wider min-w-[100px] ml-4 mr-32'>
						{title}
					</span>
					<div className='flex flex-row min-w-[850px] justify-between'>
						{holes.map((hole, holeIndex) => (
							<div
								key={holeIndex}
								className='w-10 m-3 aspect-square flex-grow-0 flex justify-around items-center space-x-4'
							>
								<span className='text-lg text-gray-500'>
									{hole.holeNumber}
								</span>
							</div>
						))}
					</div>
				</div>
				<span className='flex justify-center items-center mr-4 text-lg text-gray-700 font-medium tracking-wider'>
					{subTitle}
				</span>
			</div>
			<div className='flex flex-row justify-between border-b border-gray-400'>
				<div className='flex flex-row '>
					<span className='flex justify-start items-center text-lg text-gray-700 font-medium tracking-wider min-w-[100px] ml-4 mr-32'>
						Par
					</span>
					<div className='flex flex-row min-w-[850px] justify-between'>
						{holes.map((hole, holeIndex) => (
							<div
								key={holeIndex}
								className='w-10 m-3 aspect-square flex-grow-0 flex justify-around items-center space-x-4'
							>
								<span className='text-lg text-gray-500'>
									{hole.par}
								</span>
							</div>
						))}
					</div>
				</div>
				<span className='flex justify-center items-center mr-4 text-lg text-gray-700 font-medium tracking-wider'>
					{calculatePar()}
				</span>
			</div>
			<div className='flex flex-row justify-between border-b border-gray-400'>
				<div className='flex flex-row '>
					<span className='flex justify-start items-center text-lg text-gray-700 font-medium tracking-wider min-w-[100px] ml-4 mr-32'>
						Length
					</span>
					<div className='flex flex-row min-w-[850px] justify-between'>
						{holes.map((hole, holeIndex) => (
							<div
								key={holeIndex}
								className='w-10 m-3 aspect-square flex-grow-0 flex justify-around items-center space-x-4'
							>
								<span className='text-lg text-gray-500'>
									{hole.length}
								</span>
							</div>
						))}
					</div>
				</div>
				<span className='flex justify-center items-center mr-4 text-lg text-gray-700 font-medium tracking-wider'>
					{calculateLength()}
				</span>
			</div>
			<div className='flex flex-row justify-between border-b border-gray-400'>
				<div className='flex flex-row '>
					<span className='flex justify-start items-center text-lg text-gray-700 font-medium tracking-wider min-w-[100px] ml-4 mr-32'>
						Gross
					</span>
					<div className='flex flex-row min-w-[850px] justify-between'>
						{holes.map((hole, holeIndex) => (
							<div
								key={holeIndex}
								className={`w-10 m-3 aspect-square flex-grow-0 flex justify-around items-center space-x-4 border-gray-900 ${getScoreStyles(
									hole
								)}`}
							>
								<span className='text-lg'>{hole.gross}</span>
							</div>
						))}
					</div>
				</div>
				<span className='flex justify-center items-center mr-4 text-lg text-gray-700 font-medium tracking-wider'>
					{calculateScore()}
				</span>
			</div>
		</div>
	);
};

export default ScorecardTemplate;
