import {
	Navigate,
	Route,
	RouterProvider,
	Routes,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import './styles.css';
import ProtectedRoute from './Components/ProtectedRoute';
import PublicRoute from './Components/PublicRoute';
import EditCourse from './Components/EditCourse';
import Courses from './Components/Courses';
import MyRounds from './pages/my-rounds/MyRounds';
import NewRound from './Components/rounds/NewRound';
import Layout from './Layout';
import RoundSummary from './Components/rounds/RoundSummary';
import Stats from './Components/stats/Stats';
import RootLayout from './layouts/RootLayout';
import StatsLayout from './layouts/StatsLayout';
import DrivingStats from './Components/stats/DrivingStats';
import ApproachStats from './Components/stats/ApproachStats';
import AroundGreenStats from './Components/stats/AroundGreenStats';
import PuttingStats from './Components/stats/PuttingStats';
import ScoringStats from './Components/stats/ScoringStats';
import StatsOverview from './Components/stats/StatsOverview';
import CourseDetails from './Components/course/CourseDetails';
import Login from './pages/auth/login/Login';
import SignUpPage from './pages/auth/sign-up';
import RoundSetup from './pages/round-setup/RoundSetup';
import RoundEntry from './pages/round-entry/Components/RoundEntry';
import TournamentSetup from './pages/tournament/create/TournamentSetup';
import ExplorePage from './pages/explore';
import ProfileMenuPage from './pages/nav/profile-menu';
import ViewTournamentPage from './pages/tournament/view';
import TournamentRegistrationPage from './pages/tournament/register';
import TournamentOverviewPage from './pages/tournament/overview';
import FriendsPage from './pages/friends';
import FriendsSearchPage from './pages/friends/search';
import MyRoundsPage from './pages/rounds/my-rounds';

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path='/' element={<RootLayout />}>
			<Route
				path='/login'
				element={<PublicRoute element={<Login />} />}
			/>
			<Route
				path='/sign-up'
				element={<PublicRoute element={<SignUpPage />} />}
			/>
			<Route
				path='/:username/rounds'
				element={<ProtectedRoute element={<MyRoundsPage />} />}
			/>
			backend
			<Route path='/explore'>
				<Route
					index
					element={<Navigate to='/explore/rounds' replace />} // Redirect to Rounds by default
				/>
				<Route
					path='rounds'
					element={<ProtectedRoute element={<ExplorePage />} />} // Show ExplorePage with "Rounds" active
				/>
				<Route
					path='tournaments'
					element={<ProtectedRoute element={<ExplorePage />} />} // Show ExplorePage with "Tournaments" active
				/>
			</Route>
			<Route
				path='/:username'
				element={<ProtectedRoute element={<ProfileMenuPage />} />}
			/>
			<Route
				path='/courses/edit/:courseId'
				element={<ProtectedRoute element={<EditCourse />} />}
			/>
			<Route
				path='/courses'
				element={<ProtectedRoute element={<Courses />} />}
			/>
			<Route
				path='/new-round/:courseName'
				element={<ProtectedRoute element={<RoundSetup />} />}
			/>
			<Route
				path='/round-entry/:roundId'
				element={<ProtectedRoute element={<RoundEntry />} />}
			/>
			<Route
				path='stats'
				element={<ProtectedRoute element={<StatsLayout />} />}
			>
				<Route index element={<Navigate to='overview' replace />} />
				<Route
					path='overview'
					element={<ProtectedRoute element={<StatsOverview />} />}
				/>
				<Route
					path='driving'
					element={<ProtectedRoute element={<DrivingStats />} />}
				/>
				<Route
					path='approach'
					element={<ProtectedRoute element={<ApproachStats />} />}
				/>
				<Route
					path='around-the-green'
					element={<ProtectedRoute element={<AroundGreenStats />} />}
				/>
				<Route
					path='putting'
					element={<ProtectedRoute element={<PuttingStats />} />}
				/>
				<Route
					path='scoring'
					element={<ProtectedRoute element={<ScoringStats />} />}
				/>
			</Route>
			<Route
				path='/new-round'
				element={<ProtectedRoute element={<NewRound />} />}
			/>
			<Route
				path='/round-summary/:roundId'
				element={<ProtectedRoute element={<RoundSummary />} />}
			/>
			<Route
				path='/new-tournament'
				element={<ProtectedRoute element={<TournamentSetup />} />}
			/>
			<Route
				path='/tournament/:tournamentId'
				element={<ProtectedRoute element={<ViewTournamentPage />} />}
			/>
			<Route
				path='/tournament/:tournamentId/register'
				element={
					<ProtectedRoute element={<TournamentRegistrationPage />} />
				}
			/>
			{/* <Route
				path='/my-tournaments'
				element={
					<ProtectedRoute element={<TournamentOverviewPage />} />
				}
			/> */}
			<Route path='/my-tournaments'>
				<Route
					index
					element={<Navigate to='/my-tournaments/playing' replace />}
				/>
				<Route
					path='playing'
					element={
						<ProtectedRoute element={<TournamentOverviewPage />} />
					}
				/>
				<Route
					path='hosting'
					element={
						<ProtectedRoute element={<TournamentOverviewPage />} />
					}
				/>
			</Route>
			<Route
				path='/friends/*'
				element={<ProtectedRoute element={<FriendsPage />} />}
			/>
			<Route
				path='/friends/search'
				element={<ProtectedRoute element={<FriendsSearchPage />} />}
			/>
			<Route
				path='/'
				element={<Navigate to='/explore/rounds' replace />}
			/>
		</Route>
	)
);

function App() {
	console.log('App is rendering');
	return <RouterProvider router={router} />;
}

export default App;
