import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRandomImage } from '../../utils/utils';
import { useGetCourseDetailsQuery } from '../../features/course/courseApiSlice';
import { Typography } from '@mui/material';
import PlayersList from './Components/PlayersList';
import TeeSelectModal from './Components/TeeSelectModal';
import ScoringSelect from './Components/ScoringSelect';
import GameModeSelect from './Components/GameModeModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateRoundRequest,
	deleteRoundRequest,
} from '../../features/round/roundSlice';
import { GameModeEnum } from '../../models/Enums/gameModeEnum';
import { RootState } from '../../redux/store';
import { useCreateRoundMutation } from '../../features/round/roundApiSlice';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Button } from '../../Components/ui/Button';
import { ValidationError } from 'yup';
import { roundSchema } from '../../models/Round/newRoundRequest';
import { LayoutDetails } from '../../models/courseLayout.type';

const RoundSetup = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { courseId } = location.state || {};
	const { data: course, isFetching } = useGetCourseDetailsQuery(courseId);
	const [createRound, { isLoading, isError, data }] =
		useCreateRoundMutation();
	const [validationErrors, setValidationErrors] = useState<
		Record<string, string>
	>({});

	const round = useSelector((state: RootState) => state.round);
	const userId = useSelector((state: RootState) => state.auth.userId);

	console.log(userId);

	console.log(course);

	useEffect(() => {
		if (courseId) {
			dispatch(
				updateRoundRequest({ courseId, userId, roundDate: new Date() })
			);
		}
	}, [courseId, dispatch]);

	const handleGameModeUpdated = (gameMode: GameModeEnum) => {
		dispatch(updateRoundRequest({ gameMode }));
	};

	const handleScoringModeToggled = (logShots: boolean) => {
		dispatch(updateRoundRequest({ logShots }));
	};

	const handleTeeSelect = (layout: LayoutDetails) => {
		dispatch(
			updateRoundRequest({
				layoutId: layout.layoutId,
				courseRatingSnapshot: layout.courseRating,
				slopeRatingSnapshot: layout.slopeRating,
			})
		);
	};

	const handleCreateRound = async () => {
		try {
			await roundSchema.validate(round, { abortEarly: false });
			console.log('calling create round');
			setValidationErrors({});
			createRound(round);
			navigate('/my-rounds');
		} catch (err) {
			if (err instanceof ValidationError) {
				const errors: Record<string, string> = {};
				err.inner.forEach((error) => {
					if (error.path) {
						errors[error.path] = error.message;
					}
				});
				console.log(errors);
				setValidationErrors(errors);
			} else {
				setValidationErrors({
					general: 'An unexpected error occurred.',
				});
			}
		}
	};

	const handleDeleteRound = () => {
		navigate('/my-rounds');
		dispatch(deleteRoundRequest());
	};

	if (course) {
		return (
			<div className='relative bg-white w-full min-h-screen px-4 lg:px-[42rem] py-4 pb-20'>
				<div className='flex justify-between items-center mb-5 lg:mb-0'>
					<Typography variant='h5' className='lg:hidden'>
						New round
					</Typography>
					<div className='flex items-center justify-end px-5 space-x-2 lg:h-20 lg:fixed lg:bottom-0 lg:border-t border-gray-300 lg:w-full left-0'>
						<Button
							onClick={handleDeleteRound}
							variant={isLoading ? 'disabled' : 'outline'}
							disabled={isLoading}
							className='hidden lg:block h-10'
						>
							Cancel
						</Button>
						<div
							className='
							fixed lg:static lg:block 
							bottom-0 right-0 
							h-16 lg:h-10 
							w-full lg:w-36 
							px-4 pb-4 lg:px-0 lg:pb-0 
							z-50'
						>
							<Button
								onClick={handleCreateRound}
								loading={isLoading}
								disabled={isLoading}
								variant={isLoading ? 'disabled' : 'default'}
								className='h-full w-full'
							>
								Play
							</Button>
						</div>
					</div>
					<div className='flex lg:hidden'>
						<CloseSharpIcon
							className='cursor-pointer text-gray-700'
							style={{ fontSize: '1.8em' }}
							onClick={() => navigate('/my-rounds')}
						/>
					</div>
				</div>
				<div className='flex flex-col space-y-5'>
					<div className='flex space-x-4'>
						<img
							src={`/src/assets/images/stock-course-images/${getRandomImage()}`}
							alt=''
							className='w-1/4 lg:w-1/3 rounded object-cover'
						/>
						<div>
							<Typography variant='h6'>{course.name}</Typography>
							<Typography variant='subtitle1'>
								{course.city}, {course.country}
							</Typography>
						</div>
					</div>
					<GameModeSelect
						onSelect={handleGameModeUpdated}
						selected={round.gameMode}
					/>
					<ScoringSelect
						checked={round.logShots}
						onToggle={handleScoringModeToggled}
					/>
					<TeeSelectModal
						layouts={course.layouts}
						onSelect={handleTeeSelect}
						error={validationErrors.layoutId}
					/>
					<div className='flex flex-col'>
						<Typography variant='h6'>Players</Typography>
						<PlayersList />
					</div>
				</div>
			</div>
		);
	}
};

export default RoundSetup;
