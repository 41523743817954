import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddCourseModal from './AddCourseModal';
import InputField from './InputField';
import { updateCourse, updateHole } from '../Shared/firestoreService';
import { Oval } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Toast.css';
import { getCourse } from '../Services/courseService';

const EditCourse = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const { courseId } = useParams();
	const [course, setCourse] = useState(null);
	const [selectedTee, setSelectedTee] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const openModal = () => setModalOpen(true);

	const closeModal = () => setModalOpen(false);

	useEffect(() => {
		getCourse(courseId).then((getCourseResponse) => {
			setCourse(getCourseResponse);
			setSelectedTee(getCourseResponse.layouts[0]);
		});
	}, [courseId]);

	const sumTotal = (from, to, field) => {
		const layout = course.layouts.find(
			(l) => l.layoutId === selectedTee.layoutId
		);

		return layout.holes
			.filter((hole) => hole.holeNumber >= from && hole.holeNumber <= to)
			.sort((a, b) => a.holeNumber - b.holeNumber)
			.reduce((accumulator, currentHole) => {
				if (currentHole[field] !== null) {
					return accumulator + currentHole[field];
				}
				return accumulator;
			}, 0);
	};

	const handleTeeSelect = (event) => {
		const teeName = event.target.value;
		const layout = course.layouts.find((layout) => layout.tee === teeName);
		console.log(layout);
		setSelectedTee(layout);
	};

	const handleHoleChange = (holeIndex, field, value) => {
		setCourse((oldValue) => {
			const update = { ...oldValue };

			const hole = selectedTee.holes.find(
				(hole) => hole.holeId === holeIndex
			);
			if (hole) {
				hole[field] = parseInt(value, 10);
				hole.updated = true;
			}
			return update;
		});
	};

	const updateLayout = async () => {
		const layout = course.layouts.find(
			(layout) => layout.layoutId === selectedTee.layoutId
		);

		if (!layout) {
			console.error('Layout not found');
			return;
		}

		const holesToUpdate = layout.holes.filter(
			(hole) => hole.updated === true
		);

		const updatePromises = holesToUpdate.map((hole) =>
			updateHole(course.courseId, layout.layoutId, hole)
		);
		setIsLoading(true);
		try {
			await Promise.all(updatePromises);
			toast.success('Course updated successfully!', {
				position: 'top-center',
				className: 'toast-success',
				autoClose: 2000,
			});
		} catch (error) {
			console.error('Error updating holes: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='p-default-container'>
			<ToastContainer />
			{isLoading && (
				<div className='fixed w-full h-full inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50'>
					<div className='flex flex-col items-center bg-white px-16 py-12 rounded-md space-y-8'>
						<Oval
							color='#00b7b1'
							secondaryColor='rgb(229 231 235)'
							height={80}
							width={80}
						/>
						<p className='mt-4 tracking-wider text-gray-600 text-lg'>
							Updating Course, Please Wait.
						</p>
					</div>
				</div>
			)}
			{course ? (
				<div>
					<AddCourseModal
						isOpen={isModalOpen}
						closeModal={closeModal}
						courseId={courseId}
						courseName={course.name}
					/>
					<div className='flex flex-row justify-between items-center'>
						<h1 className='text-2xl tracking-wider font-medium text-gray-800'>
							Edit Course: {course.name}
						</h1>
						<div className='space-x-2'>
							<button
								onClick={updateLayout}
								className='
									w-[125px]
									rounded
									tracking-wider 
									border
									border-gray-400
									hover:bg-gray-100 
									hover:text-gray-700
									px-3.5 
									py-2.5 
									ease-in
									duration-100 
									text-center 
									text-base 
									font-normal 
									text-gray-500
									shadow-sm 
									focus-visible:outline 
									focus-visible:outline-2 
									focus-visible:outline-offset-2 
									focus-visible:outline-blue-700'
							>
								Update
							</button>
							<button
								onClick={openModal}
								className='
									w-[125px]
									rounded
									tracking-wider 
									border 
									border-[#00b7b1]
									hover:border-[#008793]
									bg-[#00b7b1]
									hover:bg-[#008793] 
									px-3.5 
									py-2.5 
									ease-in
									duration-100 
									text-center 
									text-base 
									font-normal 
									text-white 
									shadow-sm 
									focus-visible:outline 
									focus-visible:outline-2 
									focus-visible:outline-offset-2 
									focus-visible:outline-blue-700'
							>
								Add Layout
							</button>
						</div>
					</div>
					<select
						value={selectedTee.tee}
						onChange={handleTeeSelect}
						className='mb-6 w-64 p-2 text-gray-700 bg-white border border-gray-300 rounded shadow-sm appearance-none hover:border-gray-400 focus:outline-none focus:border-blue-500'
					>
						{course.layouts.map((layout) => (
							<option key={layout.layoutId}>{layout.tee}</option>
						))}
					</select>
					<div className='space-y-12'>
						<div>
							<div className='bg-gray-50 flex flex-row justify-between p-4 border-b border-gray-400'>
								<div className='flex flex-row items-baseline'>
									<span className='text-gray-500 text-sm tracking-widest min-w-[100px] mr-32'>
										Front Nine
									</span>
									<div className='flex flex-row min-w-[900px] basis-[54%]'>
										{selectedTee.holes
											.filter(
												(hole) =>
													hole.holeNumber >= 1 &&
													hole.holeNumber <= 9
											)
											.sort(
												(a, b) =>
													a.holeNumber - b.holeNumber
											)
											.map((hole, holeIndex) => (
												<div
													key={holeIndex}
													className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
												>
													<span
														key={holeIndex}
														className='text-sm text-gray-500'
													>
														{hole.holeNumber}
													</span>
												</div>
											))}
									</div>
								</div>
								<span className='text-sm text-gray-500 tracking-widest'>
									Out
								</span>
							</div>
							<div className='flex flex-row justify-between p-4 border-b border-gray-400 bg-white'>
								<div className='flex flex-row '>
									<span className='text-gray-700 font-medium tracking-widest min-w-[100px] mr-32'>
										Stroke
									</span>
									<div className='flex flex-row min-w-[900px] basis-[54%]'>
										{selectedTee.holes
											.filter(
												(hole) =>
													hole.holeNumber >= 1 &&
													hole.holeNumber <= 9
											)
											.sort(
												(a, b) =>
													a.holeNumber - b.holeNumber
											)
											.map((hole, holeIndex) => (
												<div
													key={holeIndex}
													className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
												>
													<span className='text-gray-500'>
														<InputField
															holeIndex={
																hole.holeId
															}
															value={hole.stroke}
															type='stroke'
															handleHoleChange={
																handleHoleChange
															}
														/>
													</span>
												</div>
											))}
									</div>
								</div>
							</div>
							<div className='flex flex-row justify-between p-4 border-b border-gray-400 bg-white'>
								<div className='flex flex-row '>
									<span className='text-gray-700 font-medium tracking-widest min-w-[100px] mr-32'>
										Par
									</span>
									<div className='flex flex-row min-w-[900px] basis-[54%]'>
										{selectedTee.holes
											.filter(
												(hole) =>
													hole.holeNumber >= 1 &&
													hole.holeNumber <= 9
											)
											.sort(
												(a, b) =>
													a.holeNumber - b.holeNumber
											)
											.map((hole, holeIndex) => (
												<div
													key={holeIndex}
													className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
												>
													<span className='text-gray-500'>
														<InputField
															holeIndex={
																hole.holeId
															}
															value={hole.par}
															type='par'
															handleHoleChange={
																handleHoleChange
															}
														/>
													</span>
												</div>
											))}
									</div>
								</div>
								<span className='text-base text-gray-500'>
									{sumTotal(1, 9, 'par')}
								</span>
							</div>
							<div className='flex flex-row justify-between p-4 border-b border-gray-400 bg-white'>
								<div className='flex flex-row '>
									<span className='text-gray-700 font-medium tracking-widest min-w-[100px] mr-32'>
										Length
									</span>
									<div className='flex flex-row min-w-[900px] basis-[54%]'>
										{selectedTee.holes
											.filter(
												(hole) =>
													hole.holeNumber >= 1 &&
													hole.holeNumber <= 9
											)
											.sort(
												(a, b) =>
													a.holeNumber - b.holeNumber
											)
											.map((hole, holeIndex) => (
												<div
													key={holeIndex}
													className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
												>
													<span className='text-lg text-gray-500'>
														<InputField
															holeIndex={
																hole.holeId
															}
															value={hole.length}
															type='length'
															handleHoleChange={
																handleHoleChange
															}
														/>
													</span>
												</div>
											))}
									</div>
								</div>
								<span className='text-base text-gray-500'>
									{sumTotal(1, 9, 'length')}
								</span>
							</div>
						</div>
						<div>
							<div className='bg-gray-50 flex flex-row justify-between p-4 border-b border-gray-400'>
								<div className='flex flex-row items-baseline'>
									<span className='text-gray-500 text-sm tracking-widest min-w-[100px] mr-32'>
										Back Nine
									</span>
									<div className='flex flex-row min-w-[900px] basis-[54%]'>
										{selectedTee.holes
											.filter(
												(hole) =>
													hole.holeNumber >= 10 &&
													hole.holeNumber <= 18
											)
											.sort(
												(a, b) =>
													a.holeNumber - b.holeNumber
											)
											.map((hole, holeIndex) => (
												<div
													key={holeIndex}
													className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
												>
													<span
														key={holeIndex}
														className='text-sm text-gray-500'
													>
														{hole.holeNumber}
													</span>
												</div>
											))}
									</div>
								</div>
								<span className='text-sm text-gray-500 tracking-widest'>
									In
								</span>
							</div>
							<div className='flex flex-row justify-between p-4 border-b border-gray-400 bg-white'>
								<div className='flex flex-row '>
									<span className='text-gray-700 font-medium tracking-widest min-w-[100px] mr-32'>
										Stroke
									</span>
									<div className='flex flex-row min-w-[900px] basis-[54%]'>
										{selectedTee.holes
											.filter(
												(hole) =>
													hole.holeNumber >= 10 &&
													hole.holeNumber <= 18
											)
											.sort(
												(a, b) =>
													a.holeNumber - b.holeNumber
											)
											.map((hole, holeIndex) => (
												<div
													key={holeIndex}
													className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
												>
													<span className='text-lg text-gray-500'>
														<InputField
															holeIndex={
																hole.holeId
															}
															value={hole.stroke}
															type='stroke'
															handleHoleChange={
																handleHoleChange
															}
														/>
													</span>
												</div>
											))}
									</div>
								</div>
							</div>
							<div className='flex flex-row justify-between p-4 border-b border-gray-400 bg-white'>
								<div className='flex flex-row '>
									<span className='text-gray-700 font-medium tracking-widest min-w-[100px] mr-32'>
										Par
									</span>
									<div className='flex flex-row min-w-[900px] basis-[54%]'>
										{selectedTee.holes
											.filter(
												(hole) =>
													hole.holeNumber >= 10 &&
													hole.holeNumber <= 18
											)
											.sort(
												(a, b) =>
													a.holeNumber - b.holeNumber
											)
											.map((hole, holeIndex) => (
												<div
													key={holeIndex}
													className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
												>
													<span className='text-lg text-gray-500'>
														<InputField
															holeIndex={
																hole.holeId
															}
															value={hole.par}
															type='par'
															handleHoleChange={
																handleHoleChange
															}
														/>
													</span>
												</div>
											))}
									</div>
								</div>
								<span className='text-base text-gray-500'>
									{sumTotal(10, 18, 'par')}
								</span>
							</div>
							<div className='flex flex-row justify-between p-4 border-b border-gray-400 bg-white'>
								<div className='flex flex-row '>
									<span className='text-gray-700 font-medium tracking-widest min-w-[100px] mr-32'>
										Length
									</span>
									<div className='flex flex-row min-w-[900px] basis-[54%]'>
										{selectedTee.holes
											.filter(
												(hole) =>
													hole.holeNumber >= 10 &&
													hole.holeNumber <= 18
											)
											.sort(
												(a, b) =>
													a.holeNumber - b.holeNumber
											)
											.map((hole, holeIndex) => (
												<div
													key={holeIndex}
													className='max-w-[5.5rem] flex-grow-0 basis-[11%] text-center'
												>
													<span className='text-lg text-gray-500'>
														<InputField
															holeIndex={
																hole.holeId
															}
															value={hole.length}
															type='length'
															handleHoleChange={
																handleHoleChange
															}
														/>
													</span>
												</div>
											))}
									</div>
								</div>
								<span className='text-base text-gray-500'>
									{sumTotal(10, 18, 'length')}
								</span>
							</div>
						</div>
					</div>

					{/* Render form with course data */}
				</div>
			) : (
				<p>Loading course data...</p>
			)}
		</div>
	);
};

export default EditCourse;
